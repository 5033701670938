import { createTheme } from "@material-ui/core/styles";

const logWindowZIndex = 1300;

const theme = createTheme({
  palette: {
    primary: {
      main: "#00673f",
    },
    secondary: {
      main: "#94C840",
    }
  },
  fairGo: {
    drawerWidth: 240
  },
  zIndex: {
    drawer: 1200,
    appBar: 1201,
    logWindow: logWindowZIndex
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "#log-window": {
          position: "fixed",
          top: "56px",
          bottom: "0",
          right: "0",
          width: "100%",
          pointerEvents: "none",
          zIndex: logWindowZIndex,
        },
        "#log-window > div": {
          height: "100%"
        },
        "@media (min-width:600px)": {
          "#log-window": {
            top: "64px",
            maxWidth: "450px",
          },
        }
      },
    },
  }
});

export default theme